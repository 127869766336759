<template>
    <CRow>
        <CCol cols="6" md="6">
            <CCard>
                <CCardBody>
                    <label>Tags</label>
<!--                    <CMultiSelect-->
<!--                            :options="allTags"-->
<!--                            :key="tagFilterKey"-->
<!--                            search-->
<!--                            searchPlaceholder="..."-->
<!--                            selectionType="tags"-->
<!--                    >-->
<!--                    </CMultiSelect>-->
                    <multiselect v-model="selectedTags"
                                 tag-placeholder="Add this as new tag"
                                 placeholder="Search or add a tag"
                                 label="name"
                                 track-by="name"
                                 :options="allTags"
                                 :multiple="true"
                                 :taggable="true"
                                 @tag="addTag"></multiselect>
                </CCardBody>
            </CCard>
        </CCol>
    </CRow>
</template>

<script>
    import {mapGetters, mapActions} from "vuex";
    import Multiselect from 'vue-multiselect'

    export default {
        name: "Tags",
        components: {
            Multiselect
        },
        data() {
            return {
                tagFilterKey: 1,
                selectedTags: null,
            }
        },
        computed: {
            ...mapGetters([
                'getCurrentTwitterTags',
            ]),
            allTags() {
                return this.getCurrentTwitterTags.tags ? this.getCurrentTwitterTags.tags : []
            },
        },
        methods: {
            ...mapActions([
                'requestCurrentTwitterTags',
            ]),

            addTag(newTag) {
                const tag = {
                    name: newTag,
                };
                //this.options.push(tag);
                this.selectedTags.push(tag)
            }

        },

        created() {
            this.requestCurrentTwitterTags();
        },
    }
</script>

<style scoped>

</style>
<style src="vue-multiselect/dist/vue-multiselect.min.css"></style>
